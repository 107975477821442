<!-- 
	NOTES FOR ME:
	we need to get all of the sessions for the day, along with:
		- name
		- start time
		- how many have been briefed
		- capacity
		- total number of children for the session 
		- how many people are onsite - change the colour of the circle based on the value
		- how many of the children are part of a school / group
-->

<template>
	<div class="h-screen relative overflow-hidden">
		<!-- hidden for now until I correctly set up the fetching of events -->
		<div v-if="loading">
			<WaveLoader />
		</div>
		<div class="bg-banner-blue">
			<div
				class="
					w-full flex text-white justify-between
					px-16 text-2xl py-2 items-center
				"
			>
				<div class="flex items-center gap-x-4">
					<div class="w-6 h-6 text-white">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							fill="currentColor"
						>
							<path
								d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
							/>
						</svg>
					</div>
					<span>
						{{ totalSignedWaivers }} / {{ totalWaivers }} waivers
						signed
					</span>
				</div>
				<div class="flex items-center">
					<div class="h-6 w-6 mr-2.5 text-white">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 32"
							xml:space="preserve"
						>
							<path
								style="stroke:currentColor;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:10;fill:none;fill-rule:nonzero;opacity:1"
								vector-effect="non-scaling-stroke"
								transform="matrix(.09 0 0 .08 18.85 16.29)"
								d="M-74.005 109.731c5 37 39.86 64.75 77.63 62.89 37.24-1.84 68.58-32 70.85-68.09 2.58-41-33.86-65.87-35.37-66.87l-.66-174.89c-1.39-19.85-17.95-35.33-37.39-35.48-20.11-.15-37.26 16.17-38 36.75.666 57.027 1.3 114.054 1.9 171.08-27.51 15.69-42.86 45.46-38.96 74.61z"
								stroke-linecap="round"
							></path>
							<path
								style="stroke:currentColor;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:none;fill-rule:nonzero;opacity:1"
								vector-effect="non-scaling-stroke"
								transform="matrix(.09 0 0 .08 25.905 2.01)"
								d="M0 27.5C0 12.312 12.312 0 27.5 0S55 12.312 55 27.5 42.688 55 27.5 55 0 42.688 0 27.5z"
								stroke-linecap="round"
							></path>
							<path
								style="stroke:currentColor;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:10;fill:none;fill-rule:nonzero;opacity:1"
								vector-effect="non-scaling-stroke"
								transform="matrix(.09 0 0 .08 -39.89 -13.443)"
								d="M467.5 355.5c2.32 2 17 14.48 38 12 24.15-2.85 35.83-22.91 37-25 10.2 17 27.74 26.82 45 25 12.69-1.34 21.39-8.57 25-12"
								stroke-linecap="round"
							></path>
							<path
								style="stroke:currentColor;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:10;fill:none;fill-rule:nonzero;opacity:1"
								vector-effect="non-scaling-stroke"
								transform="matrix(.09 0 0 .08 -39.947 -13.102)"
								d="M464.08 425.49c2.31 2 17 14.48 38 12 24.15-2.84 35.83-22.91 37-25 10.2 17 27.74 26.83 45 25"
								stroke-linecap="round"
							></path>
						</svg>
					</div>
					<span>
						Water temp:
						{{
							currentLakeTemp?.Result?.CurrentReading ??
								"An error has occurred."
						}}
					</span>
				</div>
			</div>
		</div>
		<!-- container for the grid - standard container is too small in this situation -->
		<div class="px-16">
			<div class="flex justify-center swiper z-20" ref="swiper">
				<div class="my-10 swiper-wrapper pb-8">
					<!-- 3 x 2 grid to display the events -->
					<div
						class="swiper-slide grid grid-cols-3 grid-rows-2 grid-flow-col gap-8 px-8 py-6"
						v-for="(sessions, index) in groupedSessions"
						:key="`grouped-session-${index}`"
					>
						<div
							class="bg-white p-6 rounded-3xl shadow-xl relative"
							v-for="(session, index) in sessions"
							:key="`session-${index}`"
							:class="{
								'border-4 border-turquoise-500':
									session.nextEvent,
								'border-4 border-banner-blue':
									session.currentEvent
							}"
						>
							<!-- the tab to show the next events -->
							<div
								class="bg-turquoise-500 absolute -top-6 px-8 py-2 rounded-3xl"
								v-if="session.nextEvent"
							>
								<span class="font-bold text-lg">
									Next event
								</span>
							</div>
							<div
								class="bg-banner-blue absolute -top-6 px-8 py-2 rounded-3xl"
								v-if="session.currentEvent"
							>
								<span class="font-bold text-lg text-white">
									Current event
								</span>
							</div>
							<!-- top / title section -->
							<div
								class="flex gap-x-4 items-center text-xl xl:text-3xl"
							>
								<div
									class="flex gap-x-2 items-center rounded-xl px-4 py-2"
									:class="[session.currentEvent ? 'bg-banner-blue text-white' : 'bg-turquoise-500']"
								>
									<div class="w-5 h-5">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
											fill="currentColor"
										>
											<path
												d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
											/>
										</svg>
									</div>
									<span class="font-bold">
										{{ timeConversion(session.time) }}
									</span>
								</div>
								<div class="flex flex-col gap-y-1">
									<div class="flex w-full justify-center">
										{{ session.eventPrettyName }}
									</div>
									<div class="text-lg">
										Children:
										{{ session.waivers.numOfChildren }}
									</div>
								</div>
							</div>
							<!-- middle / body -->
							<div class="grid grid-cols-2 pt-12 pb-5 xl:text-xl">
								<div
									class="flex flex-col justify-center gap-y-4 items-center"
								>
									<div class="flex gap-x-3 items-center">
										<div class="w-8 h-8">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 640 512"
											>
												<path
													d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
												/>
											</svg>
										</div>
										<span>
											Capacity
										</span>
									</div>
									<!-- to centre the capacity if only 1 eventSpace comes back from the api -->
									<div
										class="flex gap-x-4 w-full justify-between px-10"
									>
										<div
											v-if="
												session.eventSpaces.length < 2
											"
											class="text-3xl font-bold w-full flex justify-center"
										>
											{{
												session.eventSpaces[0]
													?.total - session.eventSpaces[0]?.availability ?? 0
											}}
											/
											{{
												session.eventSpaces[0]?.total ??
													0
											}}
										</div>
										<div
											v-else
											class="flex flex-col gap-x-4 items-center"
											v-for="(space,
											index) in session.eventSpaces"
											:key="`session-eventSpace-${index}`"
										>
											<div class="text-3xl font-bold">
												{{ space.total - space.availability }} /
												{{ space.total }}
											</div>
											<div class="mt-3">
												{{
													space.spaceCode.endsWith(
														"-R"
													)
														? "Right"
														: "Left"
												}}
											</div>
										</div>
									</div>
								</div>
								<div
									class="flex flex-col gap-y-10 items-center"
								>
									<div class="flex flex-col gap-y-3">
										<!-- onsite display -->
										<div class="flex gap-x-2 items-center">
											<div
												class="w-6 h-6 rounded-full"
												:class="[
													session.waivers.checkIns <
														session.waivers
															.totalWaivers ||
													session.waivers.checkIns ===
														0
														? 'bg-red-500'
														: 'bg-green-500'
												]"
											></div>
											<span>
												Onsite:
												{{ session.waivers.checkIns }}
											</span>
										</div>
										<!-- waivers display -->
										<div class="flex gap-x-2 items-center">
											<div
												class="w-6 h-6 rounded-full"
												:class="[
													session.waivers
														.hasSignedWaiver <
														session.waivers
															.totalWaivers ||
													session.waivers
														.hasSignedWaiver === 0
														? 'bg-red-500'
														: 'bg-green-500'
												]"
											></div>
											<span>
												Waivers:
												{{
													session.waivers
														.hasSignedWaiver
												}}
											</span>
										</div>
										<div
											v-if="
												session.waivers.briefed !==
													false
											"
											class="flex gap-x-2 items-center"
										>
											<div
												class="w-6 h-6 rounded-full bg-red-500"
												:class="[
													session.waivers.briefed <
														session.waivers
															.totalWaivers ||
													session.waivers.briefed ===
														0
														? 'bg-red-500'
														: 'bg-green-500'
												]"
											></div>
											<span>
												Briefed:
												{{ session.waivers?.briefed }}
											</span>
										</div>
										<div v-else>
											Briefing not required
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- bottom 'weather' app -->
		<div v-if="weatherData" class="-bottom-20 xl:bottom-0 w-full absolute -z-10">
			<div class="relative">
				<!-- wave svg background -->
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path
						fill="#374151"
						fill-opacity="1"
						d="M0,288L48,250.7C96,213,192,139,288,133.3C384,128,480,192,576,208C672,224,768,192,864,181.3C960,171,1056,181,1152,170.7C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
					></path>
				</svg>
				<!-- the data we get back from the weather api -->
				<div class="z-20 absolute top-2/3 w-full flex justify-center">
					<div
						class="
							bg-white rounded-xl w-full container 
							flex justify-center shadow-xl shadow-black items-center
						"
					>
						<div
							class="flex gap-x-10 py-4 w-full place-content-evenly"
						>
							<div
								v-for="(timeSlot, index) in weatherData"
								:key="`weather-timeSlot-${index}`"
								class="flex flex-col gap-y-2 items-center"
							>
								<span class="text-xl">
									{{ getWindDirection(timeSlot?.wind?.deg) }}
								</span>
								<div class="w-10 h-10">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										v-if="
											timeSlot.weather[0].main
												.toLowerCase()
												.includes('rain')
										"
									>
										<path
											d="M96 320c-53 0-96-43-96-96c0-42.5 27.6-78.6 65.9-91.2C64.7 126.1 64 119.1 64 112C64 50.1 114.1 0 176 0c43.1 0 80.5 24.3 99.2 60c14.7-17.1 36.5-28 60.8-28c44.2 0 80 35.8 80 80c0 5.5-.6 10.8-1.6 16c.5 0 1.1 0 1.6 0c53 0 96 43 96 96s-43 96-96 96H96zM81.5 353.9c12.2 5.2 17.8 19.3 12.6 31.5l-48 112c-5.2 12.2-19.3 17.8-31.5 12.6S-3.3 490.7 1.9 478.5l48-112c5.2-12.2 19.3-17.8 31.5-12.6zm120 0c12.2 5.2 17.8 19.3 12.6 31.5l-48 112c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5l48-112c5.2-12.2 19.3-17.8 31.5-12.6zm244.6 31.5l-48 112c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5l48-112c5.2-12.2 19.3-17.8 31.5-12.6s17.8 19.3 12.6 31.5zM313.5 353.9c12.2 5.2 17.8 19.3 12.6 31.5l-48 112c-5.2 12.2-19.3 17.8-31.5 12.6s-17.8-19.3-12.6-31.5l48-112c5.2-12.2 19.3-17.8 31.5-12.6z"
										/>
									</svg>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 640 512"
										v-if="
											timeSlot.weather[0].main
												.toLowerCase()
												.includes('clouds')
										"
									>
										<path
											d="M0 336c0 79.5 64.5 144 144 144H512c70.7 0 128-57.3 128-128c0-61.9-44-113.6-102.4-125.4c4.1-10.7 6.4-22.4 6.4-34.6c0-53-43-96-96-96c-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.7 .1 5.4 .2 8.1C40.2 219.8 0 273.2 0 336z"
										/>
									</svg>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										v-if="
											timeSlot.weather[0].main
												.toLowerCase()
												.includes('clear')
										"
									>
										<path
											d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"
										/>
									</svg>
								</div>
								<span class="text-2xl">
									{{
										timeConversion(
											timeSlot.dt_txt.split(" ")[1]
										)
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//imports
	//import Nav from '@/components/Nav.vue'
	import WaveLoader from "@/components/WaveLoader.vue";
	import Vue from "vue";

	import Swiper from "swiper";
	import { Autoplay } from "swiper/modules";
	import "swiper/swiper-bundle.min.css";

	export default {
		components: {
			WaveLoader
		},

		data: () => ({
			loading: false,
			groupedSessions: [],
			eventAks: [],
			groupedSessionObject: [],
			weatherData: [],
			performance_from: new Date().toISOString().split("T")[0],
			performance_to: new Date().toISOString().split("T")[0],
			totalSignedWaivers: 0,
			totalWaivers: 0,
			currentLakeTemp: 0,
			currentTime: new Date().toLocaleString("en-GB", {
				timeZone: "Europe/London"
			}).split(", ")[1]
		}),

		methods: {
			//non async methods

			//matching a given eventAk to the prettyName for the event
			getPrettyNameFromEventAk(eventAk) {
				const matchingEvent = this.eventAks.find(
					event => event.eventAk === eventAk
				);
				if (matchingEvent && matchingEvent.prettyName)
					return matchingEvent.prettyName;
				return ""; // Return a default value or handle the case when no matching event or prettyName is found
			},

			//converting the time from '07:00:00' to '7am
			timeConversion(time) {
				const timeParts = time.split(":");
				const hour = parseInt(timeParts[0], 10);
				const minutes = parseInt(timeParts[1], 10); // Extract the minutes from the time
				const period = hour >= 12 ? "pm" : "am";
				const formattedTime = `${hour % 12 ||
					12}:${minutes.toString().padStart(2, "0")}${period}`; // Adding minutes to the formattedTime
				return formattedTime;
			},

			getCurrentDateInFormat() {
				// get the current date
				const currentDate = new Date();
				const formattedDate = Date.UTC(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate()
				);
				return new Date(formattedDate).toISOString();
			},

			//so we can determine if the user is a child or not
			calculateUserAge(dateOfBirth) {
				return this.$moment().diff(
					this.$moment(dateOfBirth, "DD/MM/YYYY"),
					"years"
				);
			},

			// https://stackoverflow.com/questions/7490660/converting-wind-direction-in-angles-to-text-words - thanks to this guy :)
			getWindDirection(windDeg) {
				const directions = [
					"↑ N",
					"↗ NE",
					"→ E",
					"↘ SE",
					"↓ S",
					"↙ SW",
					"← W",
					"↖ NW"
				];
				return directions[Math.round(windDeg / 45) % 8];
			},

			//async methods

			async getWaterTemp() {
				this.currentLakeTemp = await Vue.prototype.$getWeatherForecast.getLakeTemperature();
			},

			async getEventPrettyName() {
				//all the data we get back from the API
				const allEventData = await Vue.prototype.$SurfEventsServiceAPI.fetchSurfEvents();
				//we only need this api to get the 'prettyName' for the events from this call
				this.eventAks = allEventData?.data.map(event => {
					return {
						prettyName: event?.prettyName,
						eventAk: event?.eventAk
					};
				});
			},

			/*
				from this method, we get back the following info:
					- availability for the day
					- the total number of people booked in for the day (these two are useful to display in the cards)
					- the eventAk so we can get the prettyName for the event
					- the time
					- the spaceCode (left or right)
			*/
			async getCalendarAvailability() {
				//set up the params for the API call
				const params = {
					performance_from: this.performance_from,
					performance_to: this.performance_to
				};
				//make the API call to get the calendar availability
				const response = await Vue.prototype.$SurfCalendarAvailability.fetchCalendarAvailability(
					params
				);

				// Event Timing
				let nextEventTime = "";
				let currentEventTime = "";

				//before we do this, lets get all of the data in each array before we split it up to sub arrays
				for(let i = 0; i < response?.data?.length; i++){
					const element = response.data[i];
					const endTime = response.data[i + 1]?.time ?? element.time;

					// if the end has passed, skip this element
					if (endTime < this.currentTime)
						continue;

					// if we havent found the current event, and this is the earliest event, set it as the current event
					if (!currentEventTime && this.currentTime < endTime)
						currentEventTime = endTime;

					// if we havent found the next event, and this is the earliest event, set it as the next event
					if (!nextEventTime && this.currentTime < element.time)
						nextEventTime = element.time;

					// could have multiple evetsn running at once, 
					// loop over all events and apply the next event and current event
					for (const [eventAk, eventSpaces] of Object.entries(
						element.data
					)) {
						this.groupedSessionObject.push({
							eventAk,
							eventSpaces,
							eventPrettyName: this.getPrettyNameFromEventAk(
								eventAk
							),
							date: element.date,
							time: element.time,
							dateTime: `${element.date}T${
								element.time.split(".")[0]
							}`,
							waivers: {
								hasSignedWaiver: 0,
								totalWaivers: 0,
								checkIns: 0,
								numOfChildren: 0,
								briefed: 0
							},
							nextEvent: nextEventTime === element?.time,
							currentEvent: currentEventTime === endTime
						});
					}
				}
			},

			async getWaiverCount() {
				const params = {
					date: this.getCurrentDateInFormat()
				};
				this.waiverResponse = await Vue.prototype.$surfWaiversAPI.fetchActiveSurfersWaivers(
					params
				);

				// resetting global vars to prevent compounding values
				this.totalSignedWaivers = 0;
				this.totalWaivers = 0;

				//resetting the waiver object so it doesn't append the values, it replaces them
				this.groupedSessionObject = this.groupedSessionObject.map(
					session => {
						session.waivers = {
							hasSignedWaiver: 0,
							totalWaivers: 0,
							checkIns: 0,
							numOfChildren: 0,
							briefed: 0
						};
						return session;
					}
				);
				//now we have a response of ALL the waivers, we need to filter this down

				//loop over all of the sessions we have and compare them to the data we get back from 'waiverResponse'
				this.waiverResponse?.data.forEach(element => {
					if (element?.event?.waiverRequired) {
						this.totalWaivers++;

						if (element.hasSignedWaiver) 
							this.totalSignedWaivers++;
					}

					if(element?.noShow === true)
						this.totalWaivers--;

					//now we are in each object, we can do the time + eventAk comparison
					this.groupedSessionObject.forEach(response => {
						//compare the responses
						if (
							element.eventAk === response.eventAk &&
							element.performanceDatetime === response.dateTime
						) {
							//if we get a match, increment the total value so we can use it to check if the user has signed the waiver etc
							if (element?.noShow === true)
								return

							response.waivers.totalWaivers++;

							//checking if the user has signed the waiver
							if (element.hasSignedWaiver === true)
								response.waivers.hasSignedWaiver++;

							//checking if the user has checked in
							if (element?.checkins?.length > 0)
								response.waivers.checkIns++;

							//checking if the user is a child and requires a hard hat
							const userAge = this.calculateUserAge(
								element.dateOfBirth
							);
							if (userAge < 10) response.waivers.numOfChildren++;

							if (element.noShow === false)
								response.waivers.briefed++;
						}
					});
				});

				//turning the array into groups of 6 - this is due to how many will be in each carousel slide
				for (let i = 0; i < this.groupedSessionObject.length; i += 6) {
					this.groupedSessions.push(
						this.groupedSessionObject.slice(i, i + 6)
					);
				}
			},

			//getting the weather forecast for the day
			async getCurrentWeatherForecast() {
				//this is to prevent the data from compounding
				this.weatherData = [];

				const params = {
					q: "Bristol",
					appid:
						process.env.VUE_APP_WAVE_SURF_SITE_OPEN_WEATHER_API_KEY
				};

				const response = await Vue.prototype.$getWeatherForecast.getForecast(
					params
				);

				response.list.forEach(element => {
					if(element.dt_txt.split(" ")[0] == this.performance_from)
						this.weatherData.push(element);
				});
			},

			//doing this to make sure that the data is loaded before the page is rendered
			async initiateData() {
				this.loading = true;
				await this.getEventPrettyName();
				await this.getCalendarAvailability();
				this.loading = false;
			},
			async refreshData() {
				this.loading = true;
				await this.getWaiverCount();
				await this.getCurrentWeatherForecast();
				
				this.loading = false;

				// Set's set a timeout to go and fetch the data again in 5 minutes
				setTimeout(() => {
					location.reload()
				}, 1000 * 60 * 5);
			},

			async asyncMounted() {
				// Get our initial data here
				await this.initiateData();

				// Refresh our data
				await this.refreshData();

				//swiper setup
				const swiper = new Swiper(this.$refs.swiper, {
					slidesPerView: 1,
					loop: true,
					modules: [Autoplay],
					autoplay: {
						delay: 10000,
						disableOnInteraction: false
					},
					spaceBetween: 50
				});
				//need this to start the autoplay (thank you Lyle!!)
				swiper.autoplay.start();
			}

			//we will need a method here to check if all of the required data has been fetched
		},

		mounted() {
			this.getWaterTemp();
			this.asyncMounted();
		}
	};
</script>

<!-- style to prevent the 'display:block' from messing up the grid -->
<style>
	.swiper-slide {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-transition-property: -webkit-transform;
		transition-property: -webkit-transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform;
		display: grid !important;
	}
</style>
